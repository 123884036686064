import React from 'react';

import {
    Datagrid,
    List,
    TextField,
    DateField,
    FunctionField,
    ReferenceField,
    ArrayField,
} from 'react-admin';

import { Permissions } from '@src/global/permissions';

import {
    BulkActionButtons,
    EditButtonCustom,
    SingleFieldListCustom,
    CapitalizedTextField,
} from '@src/custom';

import { source } from './data';

const CollateralTemplatesList = (props) => (
    <List
        {...props}
        title="Collateral Templates"
        sort={{ field: source.updatedAt, order: 'DESC' }}
        hasCreate={Permissions('co_branded_marketing_collaterals', 'create') === true}
        bulkActionButtons={<BulkActionButtons resource="collateral_templates" {...props} />}
    >
        <Datagrid>
            <FunctionField
                label="Preview"
                source={source.image}
                render={(record) => {
                    if (!record.image) {
                        return null;
                    }

                    return (
                        <img src={`${record.image.source}`} height="100" />
                    );
                }}
            />
            <TextField
                label="Template Name"
                source={source.name}
            />
            <ReferenceField
                label="Collateral Category"
                source={source.collateralCategoryId}
                reference="collateral_categories"
                link={false}
            >
                <TextField source="name" />
            </ReferenceField>
            <ArrayField
                label="Products"
                source={source.products}
                sortable={false}
            >
                <SingleFieldListCustom linkType={false}>
                    <TextField source="name" />
                </SingleFieldListCustom>
            </ArrayField>
            <DateField
                label="Publication Date"
                source={source.publishedAt}
            />
            <CapitalizedTextField
                label="Status"
                source={source.status}
            />
            <EditButtonCustom resource="co_branded_marketing_collaterals" {...props} />
        </Datagrid>
    </List>
);

export default CollateralTemplatesList;
