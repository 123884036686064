import { Taxonomies } from '@src/global/taxonomies';

import { mapAlertParams } from './paramMappers/alerts';
import { mapArticleParams } from './paramMappers/articles';
import { mapAttachmentParams } from './paramMappers/attachments';
import { mapCategoryParams } from './paramMappers/categories';
import { mapCollateralParams } from './paramMappers/collaterals';
import { mapFieldManagementNewsParams } from './paramMappers/fieldManagementNews';
import { mapGuideParams } from './paramMappers/guides';
import { mapHomeBannerParams } from './paramMappers/home_banners';
import { mapImageParams } from './paramMappers/images';
import { mapImportParams } from './paramMappers/imports';
import { mapJobAidParams } from './paramMappers/jobAids';
import { mapProductParams } from './paramMappers/products';
import { mapProductUpdateParams } from './paramMappers/productUpdates';
import { mapReassignmentParams } from './paramMappers/reassignments';
import { mapSimplePageParam } from './paramMappers/simplePages';
import { mapSocialSharingParam } from './paramMappers/socialSharings';
import { mapTrainingParams } from './paramMappers/trainings';
import { mapHomeLinkParams } from './paramMappers/home_links';
import { mapWebinarParams } from './paramMappers/webinars';
import { mapUserParams } from './paramMappers/users';
import { mapRedirectParams } from './paramMappers/redirects';
import { mapRoleParams } from './paramMappers/roles';
import { mapBrandParams } from './paramMappers/brands';
import { mapCoBrandedVideoCategoryParams } from './paramMappers/co_branded_video_categories';
import { mapCoBrandedVideoLogoParams } from './paramMappers/co_branded_video_logos';
import { mapCoBrandedVideoTemplateParams } from './paramMappers/co_branded_video_templates';
import { mapCoBrandedVideoRequestParams } from './paramMappers/co_branded_video_requests';
import { mapCollateralCategoryParams } from './paramMappers/collateral_categories';
import { mapCollateralTemplateParams } from './paramMappers/collateral_templates';
import { mapCollateralRequestParams } from './paramMappers/collateral_requests';

/**
 * Generate promize that will return parameters that should be passed
 * to the body
 * @param {string} resource - Resource for which parameters should be processed
 * @param {Hash} data - data to convert to parameters
 * @param {string} method - HTTP method to be used for request
 * @returns {Promise} - Promise object that on resolve will return parameters
 */

// eslint-disable-next-line complexity
const generateBodyParams = (resource, data, method) => {
    const isCreate = method === 'POST';

    switch (resource) {
    case 'alerts':
        return mapAlertParams(isCreate, data);
    case 'articles':
        return mapArticleParams(isCreate, data);
    case 'attachments':
        return mapAttachmentParams(isCreate, data);
    case 'carrier_commissions/imports':
        return mapImportParams(isCreate, data);
    case 'collaterals':
        return mapCollateralParams(isCreate, data);
    case 'field_management_news':
        return mapFieldManagementNewsParams(isCreate, data);
    case 'guides':
        return mapGuideParams(isCreate, data);
    case 'home_banners':
        return mapHomeBannerParams(isCreate, data);
    case 'images':
        return mapImageParams(isCreate, data);
    case 'job_aids':
        return mapJobAidParams(isCreate, data);
    case 'all_products':
        return mapProductParams(isCreate, data);
    case 'product_updates':
        return mapProductUpdateParams(isCreate, data);
    case 'reassignment':
        return mapReassignmentParams(isCreate, data);
    case 'simple_pages':
        return mapSimplePageParam(isCreate, data);
    case 'social_sharings':
        return mapSocialSharingParam(isCreate, data);
    case `taxonomies/${Taxonomies.salesAndMarketing}/categories`:
    case `taxonomies/${Taxonomies.productNewsAndUpdates}/categories`:
    case `taxonomies/${Taxonomies.systemAndTechnical}/categories`:
    case `taxonomies/${Taxonomies.collateral}/categories`:
        return mapCategoryParams(isCreate, data);
    case 'trainings':
        return mapTrainingParams(isCreate, data);
    case 'home_links':
        return mapHomeLinkParams(isCreate, data);
    case 'users':
        return mapUserParams(isCreate, data);
    case 'webinars':
        return mapWebinarParams(isCreate, data);
    case 'redirect_records':
        return mapRedirectParams(isCreate, data);
    case 'roles':
        return mapRoleParams(isCreate, data);
    case 'brands':
        return mapBrandParams(isCreate, data);
    case 'co_branded_video_categories':
        return mapCoBrandedVideoCategoryParams(isCreate, data);
    case 'co_branded_video_logos':
        return mapCoBrandedVideoLogoParams(isCreate, data);
    case 'co_branded_video_templates':
        return mapCoBrandedVideoTemplateParams(isCreate, data);
    case 'co_branded_video_requests':
        return mapCoBrandedVideoRequestParams(isCreate, data);
    case 'collateral_categories':
        return mapCollateralCategoryParams(isCreate, data);
    case 'collateral_templates':
        return mapCollateralTemplateParams(isCreate, data);
    case 'collateral_requests':
        return mapCollateralRequestParams(isCreate, data);
    default:
        return new Promise((_resolve, reject) => {
            reject(new Error(`Wrong resource: ${resource}`));
        });
    }
};

export { generateBodyParams };
