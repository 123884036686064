import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconCancel from '@material-ui/icons/Cancel';
import {
    Button,
    DateInput,
    SaveButton,
    TextInput,
    FormWithRedirect,
} from 'react-admin';

import ThumbnailInput from '@src/custom/image_input/thumbnail_input';

import Moment from 'moment';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        justifyContent: 'flex-end',
    },
    button: {
        marginLeft: theme.spacing(2),
    },
}));

const EditAttachmentForm = (props) => {
    const { attachment, updating, onCancel, onSubmit } = props;

    const classes = useStyles();

    return (
        <FormWithRedirect
            initialValues={attachment}
            resource="attachments"
            save={onSubmit}
            render={
                ({
                    handleSubmitWithRedirect,
                    pristine,
                    saving,
                }) => (
                    <>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextInput
                                    fullWidth
                                    required
                                    label="Title"
                                    source="display_name"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DateInput
                                    fullWidth
                                    label="Expiration Date"
                                    source="expired_at"
                                    parse={(v) => Moment(v).toISOString()}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <ThumbnailInput label="Thumbnail" source="thumbnail" />
                            </Grid>
                        </Grid>
                        <Toolbar disableGutters className={classes.toolbar}>
                            <Button
                                className={classes.button}
                                label="ra.action.cancel"
                                disabled={saving || updating}
                                onClick={onCancel}
                            >
                                <IconCancel />
                            </Button>
                            <SaveButton
                                className={classes.button}
                                handleSubmitWithRedirect={handleSubmitWithRedirect}
                                pristine={pristine}
                                saving={saving || updating}
                            />
                        </Toolbar>
                    </>
                )
            }
        />
    );
};

EditAttachmentForm.propTypes = {
    attachment: PropTypes.object.isRequired,
    updating: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default EditAttachmentForm;
