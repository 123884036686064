const source = {
    name: 'name',
    description: 'description',
    collateralCategoryId: 'collateral_category_id',
    image: 'image',
    secondImage: 'second_image',
    allProductsReference: 'all_products',
    products: 'products',
    stateIds: 'state_ids',
    states: 'states',
    status: 'status',
    publishedAt: 'published_at',
    createdAt: 'created_at',
    updatedAt: 'updated_at',
};

export {
    source,
};
