import React from 'react';
import PropTypes from 'prop-types';

import {
    TextInput,
    ReferenceInput,
    SelectInput,
    DateInput,
} from 'react-admin';

import Grid from '@material-ui/core/Grid';

import {
    source,
    requestStatuses,
} from './data';

const CollateralRequestForm = (props) => {
    const { resource, record } = props;

    return (
        <>
            <Grid container justify="flex-end">
                <Grid item>
                    <DateInput
                        required
                        label="Date Requested"
                        source={source.requestedAt}
                    />
                </Grid>
                <Grid item>
                    <DateInput
                        label="Date Completed"
                        source={source.completedAt}
                    />
                </Grid>
                <Grid item>
                    <SelectInput
                        label="Status"
                        source={source.status}
                        choices={requestStatuses}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="First Name"
                        source={source.firstName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="Last Name"
                        source={source.lastName}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="Email"
                        source={source.email}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ReferenceInput
                        required
                        label="State"
                        source={source.stateId}
                        resource={resource}
                        record={record}
                        reference="states"
                    >
                        <SelectInput
                            fullWidth
                            emptyText="Select state"
                            optionText="name"
                        />
                    </ReferenceInput>
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12}>
                    <ReferenceInput
                        required
                        label="Collateral Template"
                        source={source.collateralTemplateId}
                        resource={resource}
                        record={record}
                        reference="collateral_templates"
                    >
                        <SelectInput
                            fullWidth
                            emptyText="Select template"
                            optionText="name"
                        />
                    </ReferenceInput>
                </Grid>
            </Grid>
        </>
    );
};

CollateralRequestForm.propTypes = {
    resource: PropTypes.string.isRequired,
    record: PropTypes.object,
};

export default CollateralRequestForm;
