import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
} from 'react-admin';

import { apiHeaders } from '@src/services/api/helpers';

import { generateBodyParams } from './generateBodyParams';
import {
    history,
} from '../app';

import {
    getGetListURL,
    getGetOneURL,
    getGetManyURL,
    getCreateURL,
    getUpdateURL,
    getUpdateManyURL,
    getDeleteURL,
    getDeleteManyURL,
} from './requestURL';
import { processValidationError } from './process-validation-error';
import { processResponseData } from './processResponseData';

export default (type, rawResource, params) => {
    // console.log('dataProvider type: ', type);
    // console.log('dataProvider rawResource: ', rawResource);
    // console.log('dataProvider params: ', params);
    const options = {
        headers: apiHeaders(),
    };
    let resource = '';

    switch (rawResource) {
    case 'admins':
    case 'agents':
    case 'employees':
        resource = 'users';
        break;
    case 'eligibility_guides':
    case 'rate_bulletins':
    case 'underwriting_guidelines':
        resource = 'guides';
        break;
    case 'rsm_agent360':
        resource = 'simple_pages';
        break;
    case 'contact_forms':
        resource = 'form_entries';
        break;
    case 'redirects':
        resource = 'redirect_records';
        break;
    default:
        resource = rawResource;
        break;
    }

    let requestGenerator;

    switch (type) {
    case GET_LIST:
        requestGenerator = new Promise((resolve) => {
            resolve({ url: getGetListURL(resource, rawResource, params), options });
        });
        break;
    case GET_ONE:
        requestGenerator = new Promise((resolve) => {
            resolve({ url: getGetOneURL(resource, params), options });
        });
        break;
    case GET_MANY:
        requestGenerator = new Promise((resolve) => {
            resolve({ url: getGetManyURL(resource, params), options });
        });
        break;
    case CREATE:
        options.method = 'POST';
        requestGenerator = generateBodyParams(resource, params.data, options.method)
            .then((bodyParam) => {
                options.body = JSON.stringify(bodyParam);

                return { url: getCreateURL(resource), options };
            });
        break;
    case UPDATE:
        options.method = 'PUT';
        requestGenerator = generateBodyParams(resource, params.data, options.method)
            .then((bodyParam) => {
                options.body = JSON.stringify(bodyParam);

                return { url: getUpdateURL(resource, params), options };
            });
        break;
    case DELETE:
        requestGenerator = new Promise((resolve) => {
            options.method = 'DELETE';
            resolve({ url: getDeleteURL(resource, params), options });
        });
        break;
    case UPDATE_MANY:
        requestGenerator = new Promise((resolve) => {
            options.method = 'PUT';
            resolve({ url: getUpdateManyURL(resource, params), options });
        });
        break;
    case DELETE_MANY:
        requestGenerator = new Promise((resolve) => {
            options.method = 'DELETE';
            resolve({ url: getDeleteManyURL(resource, params), options });
        });
        break;
    default:
        throw new Error(`Unsupported Data Provider request type ${type}`);
    }

    let TotalCountFromHeader;
    return requestGenerator
        .then(({ url, options }) => fetch(url, options))
        .then((res) => {
            TotalCountFromHeader = res.headers.get('Total');
            if (TotalCountFromHeader) {
                TotalCountFromHeader = parseInt(TotalCountFromHeader, 10);
            }

            if (res.status === 401) {
                history.push('/unauthorized');
                const error = new Error('Authorization error');
                error.status = 401;
                throw error;
            }

            if (res.status === 422) {
                return processValidationError(res);
            }

            if (res.status >= 500) {
                throw new Error('500 Internal Server Error. Please check unique identifiers (ie. \'slug\') is not already in use.');
            }

            // We need to renew jwt token every api request
            const newJwt = res.headers.get('jwt');
            if (newJwt) {
                window.localStorage.setItem('jwt', newJwt);
            }

            return res.json();
        })
        .then((response) => (
            processResponseData(type, resource, response, TotalCountFromHeader)
        ));
};
