import React from 'react';
import PropTypes from 'prop-types';

import {
    TopToolbar,
    ListButton,
} from 'react-admin';

import { Permissions } from '@src/global/permissions';

import PremierAccessButton from './PremierAccessButton';

const ShowActions = ({ basePath, data, className, hasList }) => (
    <TopToolbar className={className}>
        { Permissions('users', 'update') === true && <PremierAccessButton user={data} /> }
        { hasList && <ListButton basePath={basePath} /> }
    </TopToolbar>
);

ShowActions.propTypes = {
    basePath: PropTypes.string.isRequired,
    className: PropTypes.string,
    hasList: PropTypes.bool,
    data: PropTypes.object,
    resource: PropTypes.string,
};

export default ShowActions;
