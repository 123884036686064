const source = {
    firstName: 'first_name',
    lastName: 'last_name',
    email: 'email',
    status: 'status',
    collateralTemplateId: 'collateral_template_id',
    stateId: 'state_id',
    state: 'state',
    requestedAt: 'requested_at',
    completedAt: 'completed_at',
    createdAt: 'created_at',
    updatedAt: 'updated_at',
};

const requestStatuses = [
    {
        id: 'created',
        name: 'New',
    },
    {
        id: 'pending',
        name: 'Pending',
    },
    {
        id: 'approved',
        name: 'Approved',
    },
    {
        id: 'rejected',
        name: 'Rejected',
    },
    {
        id: 'sent',
        name: 'Sent',
    },
];

export {
    source,
    requestStatuses,
};
