import get from 'lodash/get';

import { addError } from '@src/validations';

import { source } from './data';

export const validate = (values) => {
    const errors = {};

    if (!get(values, source.firstName)) {
        addError(errors, source.firstName, 'A First Name is required');
    }

    if (!get(values, source.lastName)) {
        addError(errors, source.lastName, 'A Last Name is required');
    }

    if (!get(values, source.email)) {
        addError(errors, source.email, 'A Email is required');
    }

    if (!get(values, source.stateId)) {
        addError(errors, source.stateId, 'A State is required');
    }

    if (!get(values, source.status)) {
        addError(errors, source.status, 'A Status is required');
    }

    return errors;
};

