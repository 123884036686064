import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
} from 'react-admin';

import {
    mapGetListAlertsData,
    mapGetListArticlesData,
    mapGetListCategoriesData,
    mapGetListCollateralsData,
    mapGetListFieldManagementNewsData,
    mapGetListGuidesData,
    mapGetListHomeBannersData,
    mapGetListHomeArticlesData,
    mapGetListJobAidsData,
    mapGetListProductUpdatesData,
    mapGetListSimplePageData,
    mapGetListSocialSharingsData,
    mapGetListTrainingsData,
    mapGetListHomeLinksData,
    mapGetListWebinarsData,
    mapGetListWritingCompaniesData,
    mapGetListRedirectsData,
    mapGetListRolesData,
    mapGetListBrandsData,
    mapGetListCoBrandedVideoCategoriesData,
    mapGetListCoBrandedVideoLogosData,
    mapGetListCoBrandedVideoTemplatesData,
    mapGetListCoBrandedVideoRequestsData,
    mapGetListCollateralCategoriesData,
    mapGetListCollateralTemplatesData,
    mapGetListCollateralRequestsData,
    mapGetManyWritingCompaniesData,
    mapGetOneAlertData,
    mapGetOneArticleData,
    mapGetOneCategoryData,
    mapGetOneCollateralData,
    mapGetOneFieldManagementNewsData,
    mapGetOneGuideData,
    mapGetOneHomeBannerData,
    mapGetOneJobAidData,
    mapGetOneProductUpdateData,
    mapGetOneSimplePageData,
    mapGetOneSocialSharingsData,
    mapGetOneTrainingData,
    mapGetOneHomeLinkData,
    mapGetOneUserData,
    mapGetOneWebinarData,
    mapGetOneRedirectData,
    mapGetOneRoleData,
    mapGetOneBrandData,
    mapGetOneCoBrandedVideoCategoryData,
    mapGetOneCoBrandedVideoLogoData,
    mapGetOneCoBrandedVideoTemplateData,
    mapGetOneCoBrandedVideoRequestData,
    mapGetOneCollateralCategoryData,
    mapGetOneCollateralTemplateData,
    mapGetOneCollateralRequestData,
} from './mappers';

import productName from '@src/utils/productName';

// eslint-disable-next-line complexity
const processResponseData = (type, resource, response, TotalCountFromHeader) => {
    // console.log(`dataProvider then ${type} ${resource} response: `, response);

    const responseData = Object.values(response)[0];
    let getListResponseData;
    let getOneResponseData = { ...responseData };
    let getManyResponseDataArray = Object.values(responseData);

    // console.log(`dataProvider then ${type} ${resource} getOneResponseData: `, getOneResponseData);
    // console.log(`dataProvider then ${type} ${resource} putResponseData: `, putResponseData);

    function handleTotal() {
        if (TotalCountFromHeader) {
            return TotalCountFromHeader;
        }

        return getListResponseData.length;
    }

    switch (type) {
    case GET_LIST:
        if (responseData) {
            if (Object.keys(response)[0] === 'taxonomy') {
                getListResponseData = responseData.categories;
            } else {
                getListResponseData = responseData;
            }
        }

        if (resource === 'alerts') {
            getListResponseData = mapGetListAlertsData(responseData);
        } else if (resource === 'articles') {
            getListResponseData = mapGetListArticlesData(responseData);
        } else if (resource === 'attachments') {
            getListResponseData = responseData;
        } else if (resource === 'carrier_commissions') {
            getListResponseData = responseData;
        } else if (resource === 'carrier_commissions/imports') {
            getListResponseData = responseData;
        } else if (resource === 'channel') {
            getListResponseData = responseData.map((responseDatum) => {
                return { id: responseDatum.slug, name: responseDatum.name };
            });
        } else if (resource === 'collaterals') {
            getListResponseData = mapGetListCollateralsData(responseData);
        } else if (resource === 'field_management_news') {
            getListResponseData = mapGetListFieldManagementNewsData(responseData);
        } else if (resource === 'guides') {
            getListResponseData = mapGetListGuidesData(responseData);
        } else if (resource === 'home_banners') {
            getListResponseData = mapGetListHomeBannersData(responseData);
        } else if (resource === 'featured_content') {
            getListResponseData = mapGetListHomeArticlesData(responseData);
        } else if (resource === 'images') {
            getListResponseData = responseData;
        } else if (resource === 'item_expirations') {
            getListResponseData = responseData;
        } else if (resource === 'job_aids') {
            getListResponseData = mapGetListJobAidsData(responseData);
        } else if (resource === 'page_expirations') {
            getListResponseData = responseData;
        } else if (resource === 'business_units') {
            getListResponseData = responseData.map((responseDatum) => {
                return {
                    id: responseDatum.id,
                    name: responseDatum.name,
                };
            });
        } else if (resource === 'products') {
            getListResponseData = responseData.map((responseDatum) => {
                return {
                    id: responseDatum.slug,
                    // eslint-disable-next-line camelcase
                    business_unit_id: responseDatum.business_unit_id,
                    // eslint-disable-next-line camelcase
                    business_unit_name: responseDatum.business_unit_name,
                    name: productName(responseDatum.name, responseDatum.platform),
                    platform: responseDatum.platform,
                    isNew: responseDatum.is_new,
                    productStates: responseDatum.product_states.map(((productState) => {
                        return {
                            id: productState.id,
                            abbreviation: productState.abbreviation.toLowerCase(),
                            name: productState.name,
                            availabilityMapStatus: productState.availability_map_status,
                            productAvailable: productState.product_available,
                        };
                    })),
                };
            });
        } else if (resource === 'all_products') {
            getListResponseData = responseData.map((responseDatum) => {
                return {
                    id: responseDatum.slug,
                    // eslint-disable-next-line camelcase
                    business_unit_id: responseDatum.business_unit_id,
                    // eslint-disable-next-line camelcase
                    business_unit_name: responseDatum.business_unit_name,
                    name: productName(responseDatum.name, responseDatum.platform),
                    platform: responseDatum.platform,
                    isNew: responseDatum.is_new,
                    productStates: responseDatum.product_states.map(((productState) => {
                        return {
                            id: productState.id,
                            abbreviation: productState.abbreviation.toLowerCase(),
                            name: productState.name,
                            availabilityMapStatus: productState.availability_map_status,
                            productAvailable: productState.product_available,
                        };
                    })),
                };
            });
        } else if (resource === 'product_updates') {
            getListResponseData = mapGetListProductUpdatesData(responseData);
        } else if (resource === 'sales_channels') {
            getListResponseData = responseData.map((responseDatum) => {
                return { id: responseDatum.slug, name: responseDatum.name };
            });
        } else if (resource === 'social_sharings') {
            getListResponseData = mapGetListSocialSharingsData(responseData);
        } else if (resource.indexOf('taxonomies/') !== -1) {
            getListResponseData = mapGetListCategoriesData(responseData);
        } else if (resource === 'trainings') {
            getListResponseData = mapGetListTrainingsData(responseData);
        } else if (resource === 'home_links') {
            getListResponseData = mapGetListHomeLinksData(responseData);
        } else if (resource === 'webinars') {
            getListResponseData = mapGetListWebinarsData(responseData);
        } else if (resource === 'states') {
            getListResponseData = responseData.map((responseDatum) => {
                return { id: responseDatum.slug, name: responseDatum.abbreviation };
            });
        } else if (resource === 'simple_pages') {
            getListResponseData = mapGetListSimplePageData(responseData);
        } else if (resource === 'users') {
            getListResponseData = responseData;
        } else if (resource === 'writing_companies') {
            getListResponseData = mapGetListWritingCompaniesData(responseData);
        } else if (resource === 'redirect_records') {
            getListResponseData = mapGetListRedirectsData(responseData);
        } else if (resource === 'roles') {
            getListResponseData = mapGetListRolesData(responseData);
        } else if (resource === 'brands') {
            getListResponseData = mapGetListBrandsData(responseData);
        } else if (resource === 'co_branded_video_categories') {
            getListResponseData = mapGetListCoBrandedVideoCategoriesData(responseData);
        } else if (resource === 'co_branded_video_logos') {
            getListResponseData = mapGetListCoBrandedVideoLogosData(responseData);
        } else if (resource === 'co_branded_video_templates') {
            getListResponseData = mapGetListCoBrandedVideoTemplatesData(responseData);
        } else if (resource === 'co_branded_video_requests') {
            getListResponseData = mapGetListCoBrandedVideoRequestsData(responseData);
        } else if (resource === 'collateral_categories') {
            getListResponseData = mapGetListCollateralCategoriesData(responseData);
        } else if (resource === 'collateral_templates') {
            getListResponseData = mapGetListCollateralTemplatesData(responseData);
        } else if (resource === 'collateral_requests') {
            getListResponseData = mapGetListCollateralRequestsData(responseData);
        } else {
            // eslint-disable-next-line no-console
            console.error(`Unhandled resource type for GET_LIST: ${resource}`);
        }

        return {
            data: getListResponseData,
            total: handleTotal(),
        };

    case GET_ONE:
        // console.log(`${type} ${resource} getOneResponseData pre: `, getOneResponseData);
        if (resource.indexOf('/categories') !== -1) {
            getOneResponseData = mapGetOneCategoryData(responseData);
        } else if (resource === 'alerts') {
            getOneResponseData = mapGetOneAlertData(responseData);
        } else if (resource === 'articles') {
            getOneResponseData = mapGetOneArticleData(responseData);
        } else if (resource === 'attachments') {
            getOneResponseData = responseData;
        } else if (resource === 'carrier_commissions/imports') {
            getOneResponseData = responseData;
        } else if (resource === 'collaterals') {
            getOneResponseData = mapGetOneCollateralData(responseData);
        } else if (resource === 'field_management_news') {
            getOneResponseData = mapGetOneFieldManagementNewsData(responseData);
        } else if (resource === 'guides') {
            getOneResponseData = mapGetOneGuideData(responseData);
        } else if (resource === 'home_banners') {
            getOneResponseData = mapGetOneHomeBannerData(responseData);
        } else if (resource === 'images') {
            getOneResponseData = responseData;
        } else if (resource === 'job_aids') {
            getOneResponseData = mapGetOneJobAidData(responseData);
        } else if (resource === 'products') {
            getOneResponseData =
                {
                    id: responseData.slug,
                    // eslint-disable-next-line camelcase
                    business_unit_id: responseData.business_unit_id,
                    name: productName(responseData.name, responseData.platform),
                    platform: responseData.platform,
                    isNew: responseData.is_new,
                    productStates: responseData.product_states.map(((productState) => {
                        return {
                            id: productState.id,
                            abbreviation: productState.abbreviation.toLowerCase(),
                            name: productState.name,
                            availabilityMapStatus: productState.availability_map_status,
                            productAvailable: productState.product_available,
                        };
                    })),
                };
        } else if (resource === 'all_products') {
            getOneResponseData =
                {
                    id: responseData.slug,
                    // eslint-disable-next-line camelcase
                    business_unit_id: responseData.business_unit_id,
                    name: productName(responseData.name, responseData.platform),
                    platform: responseData.platform,
                    isNew: responseData.is_new,
                    productStates: responseData.product_states.map(((productState) => {
                        return {
                            id: productState.id,
                            abbreviation: productState.abbreviation.toLowerCase(),
                            name: productState.name,
                            availabilityMapStatus: productState.availability_map_status,
                            productAvailable: productState.product_available,
                        };
                    })),
                };
        } else if (resource === 'business_units') {
            getListResponseData = responseData.map((responseDatum) => {
                return {
                    id: responseDatum.id,
                    name: responseDatum.name,
                };
            });
        } else if (resource === 'product_updates') {
            getOneResponseData = mapGetOneProductUpdateData(responseData);
        } else if (resource === 'social_sharings') {
            getOneResponseData = mapGetOneSocialSharingsData(responseData);
        } else if (resource === 'trainings') {
            getOneResponseData = mapGetOneTrainingData(responseData);
        } else if (resource === 'home_links') {
            getOneResponseData = mapGetOneHomeLinkData(responseData);
        } else if (resource === 'webinars') {
            getOneResponseData = mapGetOneWebinarData(responseData);
        } else if (resource === 'simple_pages') {
            getOneResponseData = mapGetOneSimplePageData(responseData);
        } else if (resource === 'users') {
            getOneResponseData = mapGetOneUserData(responseData);
        } else if (resource === 'redirect_records') {
            getOneResponseData = mapGetOneRedirectData(responseData);
        } else if (resource === 'roles') {
            getOneResponseData = mapGetOneRoleData(responseData);
        } else if (resource === 'brands') {
            getOneResponseData = mapGetOneBrandData(responseData);
        } else if (resource === 'co_branded_video_categories') {
            getOneResponseData = mapGetOneCoBrandedVideoCategoryData(responseData);
        } else if (resource === 'co_branded_video_logos') {
            getOneResponseData = mapGetOneCoBrandedVideoLogoData(responseData);
        } else if (resource === 'co_branded_video_templates') {
            getOneResponseData = mapGetOneCoBrandedVideoTemplateData(responseData);
        } else if (resource === 'co_branded_video_requests') {
            getOneResponseData = mapGetOneCoBrandedVideoRequestData(responseData);
        } else if (resource === 'collateral_categories') {
            getOneResponseData = mapGetOneCollateralCategoryData(responseData);
        } else if (resource === 'collateral_templates') {
            getOneResponseData = mapGetOneCollateralTemplateData(responseData);
        } else if (resource === 'collateral_requests') {
            getOneResponseData = mapGetOneCollateralRequestData(responseData);
        } else {
            // eslint-disable-next-line no-console
            console.error(`Unhandled resource type for GET_ONE: ${resource}`);
        }

        return {
            data: getOneResponseData,
        };
    case CREATE:
        if (resource.indexOf('/categories') !== -1) {
            getOneResponseData = mapGetOneCategoryData(responseData);
        } else if (resource === 'alerts') {
            getOneResponseData = mapGetOneAlertData(responseData);
        } else if (resource === 'articles') {
            getOneResponseData = mapGetOneArticleData(responseData);
        } else if (resource === 'collaterals') {
            getOneResponseData = mapGetOneCollateralData(responseData);
        } else if (resource === 'field_management_news') {
            getOneResponseData = mapGetOneFieldManagementNewsData(responseData);
        } else if (resource === 'guides') {
            getOneResponseData = mapGetOneGuideData(responseData);
        } else if (resource === 'home_banners') {
            getOneResponseData = mapGetOneHomeBannerData(responseData);
        } else if (resource === 'images') {
            getOneResponseData = responseData;
        } else if (resource === 'job_aids') {
            getOneResponseData = mapGetOneJobAidData(responseData);
        } else if (resource === 'product_updates') {
            getOneResponseData = mapGetOneProductUpdateData(responseData);
        } else if (resource === 'reassignment') {
            // Reassignment API call doesn't return any data but
            // react admin expect an object with `id` field.
            getOneResponseData = { id: 123456 };
        } else if (resource === 'social_sharings') {
            getOneResponseData = mapGetOneSocialSharingsData(responseData);
        } else if (resource === 'trainings') {
            getOneResponseData = mapGetOneTrainingData(responseData);
        } else if (resource === 'home_links') {
            getOneResponseData = mapGetOneHomeLinkData(responseData);
        } else if (resource === 'webinars') {
            getOneResponseData = mapGetOneWebinarData(responseData);
        } else if (resource === 'simple_pages') {
            getOneResponseData = mapGetOneSimplePageData(responseData);
        } else if (resource === 'users') {
            getOneResponseData = mapGetOneUserData(responseData);
        } else if (resource === 'redirect_records') {
            getOneResponseData = mapGetOneRedirectData(responseData);
        } else if (resource === 'roles') {
            getOneResponseData = mapGetOneRoleData(responseData);
        } else if (resource === 'brands') {
            getOneResponseData = mapGetOneBrandData(responseData);
        } else if (resource === 'co_branded_video_categories') {
            getOneResponseData = mapGetOneCoBrandedVideoCategoryData(responseData);
        } else if (resource === 'co_branded_video_logos') {
            getOneResponseData = mapGetOneCoBrandedVideoLogoData(responseData);
        } else if (resource === 'co_branded_video_templates') {
            getOneResponseData = mapGetOneCoBrandedVideoTemplateData(responseData);
        } else if (resource === 'co_branded_video_requests') {
            getOneResponseData = mapGetOneCoBrandedVideoRequestData(responseData);
        } else if (resource === 'collateral_categories') {
            getOneResponseData = mapGetOneCollateralCategoryData(responseData);
        } else if (resource === 'collateral_templates') {
            getOneResponseData = mapGetOneCollateralTemplateData(responseData);
        } else if (resource === 'collateral_requests') {
            getOneResponseData = mapGetOneCollateralRequestData(responseData);
        } else {
            // eslint-disable-next-line no-console
            console.error(`Unhandled resource type for CREATE: ${resource}`);
        }

        return {
            data: getOneResponseData,
        };
    case GET_MANY:
        // console.log(`${type} ${resource} responseData: `, responseData);
        // console.log(`${type} ${resource} getManyResponseDataArray pre: `, getManyResponseDataArray);
        if (resource === 'products') {
            getManyResponseDataArray = getManyResponseDataArray.map((item) => {
                return {
                    id: item.slug,
                    // eslint-disable-next-line camelcase
                    business_unit_id: item.business_unit_id,
                    // eslint-disable-next-line camelcase
                    business_unit_name: item.business_unit_name,
                    name: productName(item.name, item.platform),
                    platform: item.platform,
                    isNew: item.is_new,
                    states: item.states,
                    productStates: item.product_states,
                };
            });
        } else if (resource === 'all_products') {
            getManyResponseDataArray = getManyResponseDataArray.map((item) => {
                return {
                    id: item.slug,
                    // eslint-disable-next-line camelcase
                    business_unit_id: item.business_unit_id,
                    // eslint-disable-next-line camelcase
                    business_unit_name: item.business_unit_name,
                    name: productName(item.name, item.platform),
                    platform: item.platform,
                    isNew: item.is_new,
                    states: item.states,
                    productStates: item.product_states,
                };
            });
        } else if (resource === 'business_units') {
            getManyResponseDataArray = getManyResponseDataArray.map((item) => {
                return {
                    id: item.id,
                    name: item.name,
                };
            });
        } else if (resource === 'states') {
            getManyResponseDataArray = getManyResponseDataArray.map((item) => {
                return {
                    id: item.slug,
                    name: item.abbreviation,
                };
            });
        } else if (resource === 'writing_companies') {
            getManyResponseDataArray = mapGetManyWritingCompaniesData(getManyResponseDataArray);
        } else if (resource === 'roles') {
            getManyResponseDataArray = getManyResponseDataArray.map((item) => {
                return { id: item.id, name: item.name };
            });
        } else if (resource === 'brands') {
            getManyResponseDataArray = getManyResponseDataArray.map((item) => {
                return { id: item.id, name: item.name };
            });
        } else if (resource === 'collateral_categories') {
            getManyResponseDataArray = getManyResponseDataArray.map((item) => {
                return { id: item.id, name: item.name };
            });
        } else {
            getManyResponseDataArray = getManyResponseDataArray.map((item) => {
                return { id: item.slug, name: item.name };
            });
        }

        return {
            data: getManyResponseDataArray,
        };
    case UPDATE_MANY:
        return { data: [] };
    case DELETE:
        return {
            data: {
                id: responseData.slug,
            },
        };
    case DELETE_MANY:
        return { data: [] };
    case UPDATE:
        if (resource.indexOf('/categories') !== -1) {
            getOneResponseData = mapGetOneCategoryData(responseData);
        } else if (resource === 'alerts') {
            getOneResponseData = mapGetOneAlertData(responseData);
        } else if (resource === 'articles') {
            getOneResponseData = mapGetOneArticleData(responseData);
        } else if (resource === 'attachments') {
            getOneResponseData = responseData;
        } else if (resource === 'collaterals') {
            getOneResponseData = mapGetOneCollateralData(responseData);
        } else if (resource === 'field_management_news') {
            getOneResponseData = mapGetOneFieldManagementNewsData(responseData);
        } else if (resource === 'guides') {
            getOneResponseData = mapGetOneGuideData(responseData);
        } else if (resource === 'home_banners') {
            getOneResponseData = mapGetOneHomeBannerData(responseData);
        } else if (resource === 'images') {
            getOneResponseData = responseData;
        } else if (resource === 'job_aids') {
            getOneResponseData = mapGetOneJobAidData(responseData);
        } else if (resource === 'all_products') {
            getOneResponseData = responseData;
        } else if (resource === 'product_updates') {
            getOneResponseData = mapGetOneProductUpdateData(responseData);
        } else if (resource === 'social_sharings') {
            getOneResponseData = mapGetOneSocialSharingsData(responseData);
        } else if (resource === 'trainings') {
            getOneResponseData = mapGetOneTrainingData(responseData);
        } else if (resource === 'home_links') {
            getOneResponseData = mapGetOneHomeLinkData(responseData);
        } else if (resource === 'webinars') {
            getOneResponseData = mapGetOneWebinarData(responseData);
        } else if (resource === 'simple_pages') {
            getOneResponseData = mapGetOneSimplePageData(responseData);
        } else if (resource === 'users') {
            getOneResponseData = mapGetOneUserData(responseData);
        } else if (resource === 'redirect_records') {
            getOneResponseData = mapGetOneRedirectData(responseData);
        } else if (resource === 'roles') {
            getOneResponseData = mapGetOneRoleData(responseData);
        } else if (resource === 'brands') {
            getOneResponseData = mapGetOneBrandData(responseData);
        } else if (resource === 'co_branded_video_categories') {
            getOneResponseData = mapGetOneCoBrandedVideoCategoryData(responseData);
        } else if (resource === 'co_branded_video_logos') {
            getOneResponseData = mapGetOneCoBrandedVideoLogoData(responseData);
        } else if (resource === 'co_branded_video_templates') {
            getOneResponseData = mapGetOneCoBrandedVideoTemplateData(responseData);
        } else if (resource === 'co_branded_video_requests') {
            getOneResponseData = mapGetOneCoBrandedVideoRequestData(responseData);
        } else if (resource === 'collateral_categories') {
            getOneResponseData = mapGetOneCollateralCategoryData(responseData);
        } else if (resource === 'collateral_templates') {
            getOneResponseData = mapGetOneCollateralTemplateData(responseData);
        } else if (resource === 'collateral_requests') {
            getOneResponseData = mapGetOneCollateralRequestData(responseData);
        } else {
            // eslint-disable-next-line no-console
            console.error(`Unhandled resource type for UPDATE: ${resource}`);
        }

        return {
            data: getOneResponseData,
        };
    default:
        return {
            data: responseData,
        };
    }
};

export { processResponseData };
