import React from 'react';
import PropTypes from 'prop-types';

import { BulkDeleteButton, BulkUpdateButton } from 'react-admin';

import { Permissions } from '@src/global/permissions';

const BulkActionButtons = (props) => {
    const { resource } = props;

    let res = resource;
    if (res === 'admins' || res === 'employees' || res === 'agents') {
        res = 'users';
    }

    if (res === 'contact_forms') {
        res = 'form_entries';
    }

    if (Permissions(res, 'delete') === false) {
        return null;
    }

    return (
        <>
            {
                resource === 'agents' && Permissions('users', 'update') === true && (
                    <>
                        <BulkUpdateButton
                            label="Grant Premiere Access"
                            icon={null}
                            basePath="/users"
                            // eslint-disable-next-line camelcase
                            data={{ premier_access: true }}
                        />
                        <BulkUpdateButton
                            label="Revoke Premiere Access"
                            icon={null}
                            basePath="/users"
                            // eslint-disable-next-line camelcase
                            data={{ premier_access: false }}
                        />
                    </>
                )
            }
            <BulkDeleteButton {...props} undoable={false} />
        </>
    );
};

BulkActionButtons.propTypes = {
    resource: PropTypes.string,
};

export default BulkActionButtons;
