import React from 'react';
import PropTypes from 'prop-types';

import {
    TextInput,
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput,
} from 'react-admin';

import {
    ProductStatesSelect,
    StyledSelectArrayInput,
    PublicationDateInput,
    ImageInput,
} from '@src/custom';

import { FirstRow } from '@src/components/FormHeader';

import Grid from '@material-ui/core/Grid';

import { status } from '@src/global/choices';
import { source } from './data';

const CollateralTemplateForm = (props) => {
    const { resource, record } = props;

    return (
        <>
            <FirstRow key="first-row" isShowAuthor={false} {...props} source={source}>
                <PublicationDateInput resource={resource} source={source} />
                <SelectInput
                    source={source.status}
                    choices={status}
                />
            </FirstRow>
            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="Name"
                        source={source.name}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        label="Description"
                        source={source.description}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={6}>
                    <ReferenceInput
                        required
                        label="Category"
                        source={source.collateralCategoryId}
                        resource={resource}
                        record={record}
                        reference="collateral_categories"
                    >
                        <SelectInput
                            fullWidth
                            emptyText="Select category"
                            optionText="name"
                        />
                    </ReferenceInput>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        required
                        label="Applicable Products"
                        reference={source.allProductsReference}
                        source={source.products}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth groupBy="business_unit_name" />
                    </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ProductStatesSelect
                        key="select-array-input-states"
                        required
                        fullWidth
                        label="Applicable States"
                        source={source.states}
                        productsSource={source.products}
                        resource={resource}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={6}>
                    <ImageInput
                        isRequired
                        label="Upload Front Image"
                        folderName="collateral"
                        source={source.image}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ImageInput
                        isRequired
                        label="Upload Back Image"
                        folderName="collateral"
                        source={source.secondImage}
                    />
                </Grid>
            </Grid>
        </>
    );
};

CollateralTemplateForm.propTypes = {
    resource: PropTypes.string.isRequired,
    record: PropTypes.object,
};

export default CollateralTemplateForm;
