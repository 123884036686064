function mapGetOneCollateralRequestData(responseData) {
    return {
        ...responseData,
    };
}

function mapGetListCollateralRequestsData(responseData) {
    return responseData.map((data) => ({
        ...data,
    }));
}

export { mapGetOneCollateralRequestData, mapGetListCollateralRequestsData };
