import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Button,
    TextInput,
    SelectInput,
    NumberInput,
    DateInput,
    ReferenceInput,
    ReferenceArrayInput,
    useNotify,
    useRefresh,
} from 'react-admin';

import {
    ImageInput,
    StyledSelectArrayInput,
    RichTextInput,
} from '@src/custom';

import copyStringToClipboard from '@src/utils/copyStringToClipboard';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { callApi } from '@src/services/api/helpers';

import {
    source,
    requestStatuses,
    requestLogoIncludedOptions,
    requestShowFontOptions,
    approvalEmailText,
    invalidProducerCodeEmailText,
} from './data';

import styles from './form.scss';

const CoBrandedVideoRequestForm = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const { resource, record } = props;

    let emailText = approvalEmailText;
    if (record.status === 'rejected') {
        emailText = invalidProducerCodeEmailText;
    }

    if (emailText.includes('[NAME]')) {
        emailText = emailText.replace('[NAME]', record.first_name);
    }

    if (emailText.includes('[AUTO INSERT LINKS]') && record.brands && record.brands.length > 0) {
        let links = '<p>';
        record.brands.map((b, index) => {
            if (record.co_branded_video_request_brands && record.co_branded_video_request_brands.length > 0) {
                record.co_branded_video_request_brands[index].template_generated_urls.map((gu, i) => {
                    const sourceUrl = record.co_branded_video_request_brands[index].template_sources[i];
                    let downloadUrl = sourceUrl;
                    if (downloadUrl && downloadUrl.includes('upload/')) {
                        downloadUrl = downloadUrl.replace('upload/', 'upload/fl_attachment/');
                    }

                    let name = record.co_branded_video_request_brands[index].template_names[i];
                    if (!name.includes(b.name)) {
                        name = `${b.name} ${name}`;
                    }

                    links += `<a target="_blank" href=${downloadUrl}>${name}</a>`;
                    links += '<br />';

                    return null;
                });
            }

            return null;
        });
        links += '</p>';

        emailText = emailText.replace('[AUTO INSERT LINKS]', links);
    }

    const [currentTab, setCurrentTab] = useState(0);
    const [userState, setUserState] = useState(record.state.abbreviation);
    const [rbs, setRbs] = useState(record.co_branded_video_request_brands);
    const [emailBody, setEmailBody] = useState(emailText);
    const [loading, setLoading] = useState(false);

    const handleTabChange = useCallback((event, tabId) => {
        setCurrentTab(tabId);
    }, [setCurrentTab]);

    const handleStateChange = useCallback((event) => {
        setUserState(event.target.value.toUpperCase());
        setLoading(true);
        record.co_branded_video_request_brands.map(async (rb, i) => {
            try {
                const res = await callApi(`/co_branded_video_templates?brand_id=${rb.brand_id}&state=${event.target.value}`, {
                    method: 'GET',
                });
                // eslint-disable-next-line camelcase
                const { co_branded_video_templates } = res.data;
                // eslint-disable-next-line camelcase
                if (co_branded_video_templates) {
                    const currentIds = rbs[i].co_branded_video_template_ids;
                    // eslint-disable-next-line camelcase
                    const templateIds = co_branded_video_templates.map((t) => t.id);
                    // eslint-disable-next-line camelcase
                    rbs[i].co_branded_video_template_ids = currentIds.filter((id) => templateIds.includes(id));
                    setRbs(rbs);
                    setLoading(false);
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
            }

            return null;
        });
    }, [setUserState, rbs, record]);

    const handleBrandLogoChange = useCallback(async (i, e) => {
        setLoading(true);
        try {
            const res = await callApi(`/co_branded_video_logos/${e.target.value}`, {
                method: 'GET',
            });
            const { source } = res.data.co_branded_video_logo.image;
            if (source) {
                // eslint-disable-next-line camelcase
                rbs[i].brand_logo_source = source;
                setRbs(rbs);
                setLoading(false);
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }, [setRbs, rbs, setLoading]);

    const handleAgencyLogoChange = useCallback(async (i, logo) => {
        setLoading(true);
        try {
            // eslint-disable-next-line camelcase
            rbs[i].agency_logo = logo;
            const res = await callApi(`/images/${logo.id}`, {
                method: 'GET',
            });
            // eslint-disable-next-line camelcase
            rbs[i].agency_logo = res.data.image;
            setRbs(rbs);
            setLoading(false);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }, [setRbs, rbs, setLoading]);

    const handleLogoIncludedChange = useCallback((i, e) => {
        // eslint-disable-next-line camelcase
        rbs[i].agency_logo_included = e.target.value;
        setRbs(rbs);
    }, [setRbs, rbs]);

    const handleAgencyNameChange = useCallback((i, e) => {
        // eslint-disable-next-line camelcase
        rbs[i].agency_name = e.target.value;
        setRbs(rbs);
    }, [setRbs, rbs]);

    const handleLogoSizeChange = useCallback((i, e) => {
        let percentage = Number(e.target.value);
        if (percentage < 0) {
            percentage = 0;
        }

        if (percentage > 100) {
            percentage = 100;
        }

        // eslint-disable-next-line camelcase
        rbs[i].agency_logo_size_percentage = percentage;
        // Minimum: max-width: 52%;
        // Maximum: max-width: 62%;
        // eslint-disable-next-line camelcase
        rbs[i].agency_logo_width = Math.ceil((percentage / 10)) + 52;
        // Minimum: max-height: 58%;
        // Maximum: max-height: 82%;
        // eslint-disable-next-line camelcase
        rbs[i].agency_logo_height = Math.ceil((percentage / (100 / 24))) + 58;
        // Minimum: font-size: 20px;
        // Maximum: font-size: 26px;
        // eslint-disable-next-line camelcase
        rbs[i].agency_logo_name_size = Math.min((Math.ceil(percentage / (100 / 6)) + 20), 26);

        setRbs(rbs);
    }, [setRbs, rbs]);

    const handlePhoneNumberChange = useCallback((i, e) => {
        // eslint-disable-next-line camelcase
        rbs[i].agency_phone_number = e.target.value;
        setRbs(rbs);
    }, [setRbs, rbs]);

    const handlePhoneNumberEnabledChange = useCallback((i, e) => {
        // eslint-disable-next-line camelcase
        rbs[i].agency_phone_number_enabled = e.target.value;
        setRbs(rbs);
    }, [setRbs, rbs]);

    const handleEmailChange = useCallback((i, e) => {
        // eslint-disable-next-line camelcase
        rbs[i].agency_email = e.target.value;
        setRbs(rbs);
    }, [setRbs, rbs]);

    const handleEmailEnabledChange = useCallback((i, e) => {
        // eslint-disable-next-line camelcase
        rbs[i].agency_email_enabled = e.target.value;
        setRbs(rbs);
    }, [setRbs, rbs]);

    const handleWebsiteChange = useCallback((i, e) => {
        // eslint-disable-next-line camelcase
        rbs[i].agency_website = e.target.value;
        setRbs(rbs);
    }, [setRbs, rbs]);

    const handleWebsiteEnabledChange = useCallback((i, e) => {
        // eslint-disable-next-line camelcase
        rbs[i].agency_website_enabled = e.target.value;
        setRbs(rbs);
    }, [setRbs, rbs]);

    const handleLicenseChange = useCallback((i, e) => {
        // eslint-disable-next-line camelcase
        rbs[i].license_number = e.target.value;
        setRbs(rbs);
    }, [setRbs, rbs]);

    const copyCallback = useCallback((value) => {
        copyStringToClipboard(value)
            .then(() => {
                notify('Url has been copied to clipboard', 'info');
            }).catch(() => {
                notify('Url could not be copied to clipboard', 'error');
            });
    }, [notify]);

    const handleEmailBodyChange = useCallback((data) => {
        setEmailBody(data);
    }, [setEmailBody]);

    const handleSendEmail = useCallback(async () => {
        try {
            const res = await callApi(`/co_branded_video_requests/${record.id}/send_email`, {
                method: 'POST',
                data: {
                    body: emailBody,
                },
            });
            notify(`${res.data.message}`, 'info');
            refresh();
        } catch (e) {
            notify(`Error: ${e.message}`);
        }
    }, [record, emailBody, notify, refresh]);

    return (
        <>
            <Grid container justify="flex-end">
                <Grid item>
                    <DateInput
                        required
                        label="Date Requested"
                        source={source.requestedAt}
                    />
                </Grid>
                <Grid item>
                    <DateInput
                        label="Date Completed"
                        source={source.completedAt}
                    />
                </Grid>
                <Grid item>
                    <SelectInput
                        label="Status"
                        source={source.status}
                        choices={requestStatuses}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="First Name"
                        source={source.firstName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="Last Name"
                        source={source.lastName}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="Requester Email"
                        source={source.requesterEmail}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ReferenceInput
                        required
                        label="State"
                        source={source.stateId}
                        resource={resource}
                        record={record}
                        reference="states"
                        onChange={handleStateChange}
                    >
                        <SelectInput
                            fullWidth
                            emptyText="Select state"
                            optionText="name"
                        />
                    </ReferenceInput>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        label="Foremost Producer Code"
                        source={source.foremostProducerCode}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        label="Bristol Producer Code"
                        source={source.bristolProducerCode}
                    />
                </Grid>
            </Grid>

            {
                record.brands && record.brands.length > 0 && (
                    <>
                        <Grid item>
                            <Tabs
                                value={currentTab}
                                variant="fullWidth"
                                indicatorColor="secondary"
                                textColor="secondary"
                                aria-label="icon label tabs example"
                                onChange={handleTabChange}
                            >
                                {
                                    record.brands.map((b) => {
                                        return (
                                            <Tab key={b.id} label={b.name} />
                                        );
                                    })
                                }
                            </Tabs>
                        </Grid>

                        <Grid container>
                            {
                                // eslint-disable-next-line complexity
                                record.brands.map((b, index) => {
                                    return (
                                        <Grid
                                            key={`tab-panel-${b.id}`}
                                            item
                                            xs={12}
                                            md={12}
                                            value={index}
                                            index={index}
                                            hidden={index !== currentTab}
                                        >
                                            <h3>{ `${b.name} Customization Options for Your Video` }</h3>
                                            <Grid container>
                                                <Grid item xs={12} md={6}>
                                                    <ReferenceInput
                                                        required
                                                        label="Select the logo you want to use in the video"
                                                        source={`co_branded_video_request_brands.${index}.co_branded_video_logo_id`}
                                                        resource={resource}
                                                        record={record}
                                                        reference="co_branded_video_logos"
                                                        // eslint-disable-next-line camelcase
                                                        filter={{ brand_id: b.id }}
                                                        onChange={(v) => handleBrandLogoChange(index, v)}
                                                    >
                                                        <SelectInput
                                                            fullWidth
                                                            emptyText="Select the logo"
                                                        />
                                                    </ReferenceInput>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    {
                                                        !loading &&
                                                        <ReferenceArrayInput
                                                            key="select-array-input-video-templates"
                                                            required
                                                            label="Pick the videos to customize"
                                                            reference="co_branded_video_templates"
                                                            source={`co_branded_video_request_brands.${index}.co_branded_video_template_ids`}
                                                            resource={resource}
                                                            // eslint-disable-next-line camelcase
                                                            filter={{ state: userState, brand_id: b.id }}
                                                        >
                                                            <StyledSelectArrayInput fullWidth />
                                                        </ReferenceArrayInput>
                                                    }
                                                </Grid>
                                            </Grid>

                                            <Grid container>
                                                <Grid item xs={12} md={6}>
                                                    <SelectInput
                                                        fullWidth
                                                        label="Do You Want to Include Your Agency's Logo?"
                                                        source={`co_branded_video_request_brands.${index}.agency_logo_included`}
                                                        choices={requestLogoIncludedOptions}
                                                        onChange={(v) => handleLogoIncludedChange(index, v)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextInput
                                                        fullWidth
                                                        label="Phone Number"
                                                        source={`co_branded_video_request_brands.${index}.agency_phone_number`}
                                                        onChange={(v) => handlePhoneNumberChange(index, v)}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container>
                                                <Grid item xs={12} md={6}>
                                                    <TextInput
                                                        fullWidth
                                                        label="Email"
                                                        source={`co_branded_video_request_brands.${index}.agency_email`}
                                                        onChange={(v) => handleEmailChange(index, v)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextInput
                                                        fullWidth
                                                        label="Website"
                                                        source={`co_branded_video_request_brands.${index}.agency_website`}
                                                        onChange={(v) => handleWebsiteChange(index, v)}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={12} md={12}>
                                                    {
                                                        rbs && rbs[index].agency_logo_included &&
                                                            <ImageInput
                                                                isRequired
                                                                source={`co_branded_video_request_brands.${index}.agency_logo`}
                                                                label="Agency Logo"
                                                                folderName="agency_logos"
                                                                allowEdit={false}
                                                                maxSize={5000000}
                                                                onChange={(v) => handleAgencyLogoChange(index, v)}
                                                            />
                                                    }
                                                    {
                                                        rbs && !rbs[index].agency_logo_included &&
                                                            <TextInput
                                                                fullWidth
                                                                isRequired
                                                                label="Agency Name"
                                                                source={`co_branded_video_request_brands.${index}.agency_name`}
                                                                onChange={(v) => handleAgencyNameChange(index, v)}
                                                            />
                                                    }
                                                </Grid>
                                            </Grid>

                                            <h3>{ `${b.name} Video Preview` }</h3>
                                            <Grid container>
                                                <Grid item xs={12} md={6}>
                                                    {
                                                        loading &&
                                                        <span>Loading ...</span>
                                                    }
                                                    {
                                                        !loading &&
                                                        <div className={styles['preview-area']}>
                                                            <div className="agency-logo">
                                                                {
                                                                    rbs && rbs[index].agency_logo_included && rbs[index].agency_logo && rbs[index].agency_logo.source &&
                                                                    <span className="agency-logo-wrapper">
                                                                        <img
                                                                            className="agency-logo-image"
                                                                            style={{
                                                                                maxWidth: `${rbs[index].agency_logo_width}%`,
                                                                                maxHeight: `${rbs[index].agency_logo_height}%`,
                                                                            }}
                                                                            src={rbs[index].agency_logo.source}
                                                                        />
                                                                    </span>
                                                                }
                                                                {
                                                                    rbs && !rbs[index].agency_logo_included &&
                                                                    <div
                                                                        className="agency-custom-name"
                                                                        style={{
                                                                            fontSize: `${rbs[index].agency_logo_name_size}px`,
                                                                        }}
                                                                    >
                                                                        { rbs[index].wrapped_agency_name }
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="agency-brand-details">
                                                                {
                                                                    rbs && rbs[index].agency_phone_number && rbs[index].agency_phone_number_enabled &&
                                                                    <p>
                                                                        <span>
                                                                            { rbs[index].agency_phone_number }
                                                                        </span>
                                                                    </p>
                                                                }
                                                                {
                                                                    rbs && rbs[index].agency_email && rbs[index].agency_email_enabled &&
                                                                    <p>
                                                                        <span>
                                                                            { rbs[index].agency_email }
                                                                        </span>
                                                                    </p>
                                                                }
                                                                {
                                                                    rbs && rbs[index].agency_website && rbs[index].agency_website_enabled &&
                                                                    <p>
                                                                        <span>
                                                                            { rbs[index].agency_website }
                                                                        </span>
                                                                    </p>
                                                                }
                                                            </div>
                                                            <div className="agency-brand-logo">
                                                                {
                                                                    rbs && rbs[index].brand_logo_source &&
                                                                    <img
                                                                        className="agency-brand-image"
                                                                        src={rbs[index].brand_logo_source}
                                                                    />
                                                                }
                                                            </div>
                                                            <div className="template-disclaimer">
                                                                <span>
                                                                    Not all products, coverages, features, and discounts are available in all areas or states and may
                                                                    vary. Restrictions, exclusions, limits, and conditions apply. Please read the policy as this piece
                                                                    does not alter the coverages in any policy you purchase. Advertisement produced on behalf of the
                                                                    following specific insurers seeking to obtain business for insurance underwritten by Foremost Insurance
                                                                    Company Grand Rapids, Michigan, Foremost County Mutual Insurance Company, or Foremost Lloyds of Texas.
                                                                    Home office, Caledonia, MI; or advertisement produced von behalf of the following specific insurers
                                                                    seeking to obtain business for insurance underwritten by Farmers Property and Casualty Insurance Company,
                                                                    Farmers Casualty Insurance Company, Farmers Group Property and Casualty Insurance Company, Farmers Direct
                                                                    Property and Casualty Insurance Company, Farmers Lloyds Insurance Company of Texas, Economy Preferred
                                                                    Insurance Company, or Economy Fire & Casualty Insurance Company, all with administrative home offices,
                                                                    Warwick, RI, and each a part of Farmers Insurance Group®. Each insurer has sole financial responsibility
                                                                    for its own insurance. States where licensed at foremost.com/companies.
                                                                </span>
                                                                {
                                                                    rbs && rbs[index].license_number &&
                                                                    <span>
                                                                        { ` License # ${rbs[index].license_number}` }
                                                                    </span>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <NumberInput
                                                        fullWidth
                                                        min={0}
                                                        max={100}
                                                        step={1}
                                                        label="Logo Size Percentage"
                                                        source={`co_branded_video_request_brands.${index}.agency_logo_size_percentage`}
                                                        parse={(v) => parseInt(v, 10)}
                                                        onChange={(v) => handleLogoSizeChange(index, v)}
                                                    />
                                                    <SelectInput
                                                        fullWidth
                                                        label="Phone Number Font"
                                                        source={`co_branded_video_request_brands.${index}.agency_phone_number_enabled`}
                                                        choices={requestShowFontOptions}
                                                        onChange={(v) => handlePhoneNumberEnabledChange(index, v)}
                                                    />
                                                    <SelectInput
                                                        fullWidth
                                                        label="Email Font"
                                                        source={`co_branded_video_request_brands.${index}.agency_email_enabled`}
                                                        choices={requestShowFontOptions}
                                                        onChange={(v) => handleEmailEnabledChange(index, v)}
                                                    />
                                                    <SelectInput
                                                        fullWidth
                                                        label="Website Font"
                                                        source={`co_branded_video_request_brands.${index}.agency_website_enabled`}
                                                        choices={requestShowFontOptions}
                                                        onChange={(v) => handleWebsiteEnabledChange(index, v)}
                                                    />
                                                    {
                                                        (userState === 'CA') &&
                                                        <TextInput
                                                            required
                                                            fullWidth
                                                            label="License Number"
                                                            source={`co_branded_video_request_brands.${index}.license_number`}
                                                            onChange={(v) => handleLicenseChange(index, v)}
                                                        />
                                                    }
                                                </Grid>
                                            </Grid>
                                            <h3>{ `${b.name} Videos Requested` }</h3>
                                            {
                                                record.co_branded_video_request_brands && record.co_branded_video_request_brands[index].template_generated_urls.map((gu, i) => {
                                                    const sourceUrl = record.co_branded_video_request_brands[index].template_sources[i];
                                                    let downloadUrl = sourceUrl;
                                                    if (downloadUrl && downloadUrl.includes('upload/')) {
                                                        downloadUrl = downloadUrl.replace('upload/', 'upload/fl_attachment/');
                                                    }

                                                    return (
                                                        <Grid key={`request-brand-${b.id}-template-${record.co_branded_video_request_brands[index].id}-${gu}`} container spacing={2} border="1px solid">
                                                            <Grid item xs={12} md={6}>
                                                                <span>{ record.co_branded_video_request_brands[index].template_names[i] }</span>
                                                            </Grid>
                                                            <Grid item xs={12} md={2}>
                                                                {
                                                                    record.generating &&
                                                                    <span>Generating new video. Please allow up to 5 minutes.</span>
                                                                }
                                                                {
                                                                    !record.generating &&
                                                                    <Button
                                                                        label="Download Video"
                                                                        target="_blank"
                                                                        href={downloadUrl}
                                                                    />
                                                                }
                                                            </Grid>
                                                            <Grid item xs={12} md={2}>
                                                                {
                                                                    !record.generating &&
                                                                    <Button
                                                                        label="Copy Video URL"
                                                                        onClick={() => copyCallback(sourceUrl)}
                                                                    >
                                                                        <FileCopyIcon />
                                                                    </Button>
                                                                }
                                                            </Grid>
                                                            <Grid item xs={12} md={2}>
                                                                {
                                                                    !record.generating &&
                                                                    <Button
                                                                        label="Play Video"
                                                                        target="_blank"
                                                                        href={sourceUrl}
                                                                    />
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })
                                            }
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </>
                )
            }
            {
                (record.status === 'approved' || record.status === 'rejected' || record.status === 'sent') && !record.generating &&
                <Grid container>
                    <Grid item>
                        <h3>Email</h3>
                        <RichTextInput
                            required
                            label={`${record.status} email`}
                            defValue={emailBody}
                            source="email_body"
                            onChange={handleEmailBodyChange}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            label="Send Co-Branded Video Email"
                            onClick={handleSendEmail}
                        />
                    </Grid>
                </Grid>
            }
        </>
    );
};

CoBrandedVideoRequestForm.propTypes = {
    resource: PropTypes.string.isRequired,
    record: PropTypes.object,
};

export default CoBrandedVideoRequestForm;
