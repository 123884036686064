import {
    cleanCommonParams,
} from './common';

const mapCollateralRequestParams = (
    isCreate,
    {
        ...params
    },
) => {
    return new Promise((resolve) => {
        resolve({
            // eslint-disable-next-line camelcase
            collateral_request: {
                ...cleanCommonParams(params),
            },
        });
    });
};

export { mapCollateralRequestParams };
