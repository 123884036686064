import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { apiPatch } from '@src/services/api/helpers';

import {
    Button,
} from 'react-admin';

const PremierAccessButton = ({ user }) => {
    const [isPremierAccess, setPremierAccess] = useState(null);

    useEffect(() => {
        if (!user) {
            return;
        }

        setPremierAccess(user.premier_access);
    }, [setPremierAccess, user]);

    const handleGrantAccessButtonClick = useCallback(() => {
        apiPatch(`/users/${user.id}/grant_premier_access`).then(() => {
            setPremierAccess(true);
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }, [setPremierAccess, user]);

    const handleRevokeAccessButtonClick = useCallback(() => {
        apiPatch(`/users/${user.id}/revoke_premier_access`).then(() => {
            setPremierAccess(false);
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }, [setPremierAccess, user]);

    return (
        <>
            {
                user && !isPremierAccess &&
                    <Button
                        label="Grant Premiere Access"
                        onClick={handleGrantAccessButtonClick}
                    />
            }

            {
                user && isPremierAccess &&
                    <Button
                        label="Revoke Premiere Access"
                        onClick={handleRevokeAccessButtonClick}
                    />
            }
        </>
    );
};

PremierAccessButton.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        // eslint-disable-next-line camelcase
        premier_access: PropTypes.bool.isRequired,
    }).isRequired,
};

export default PremierAccessButton;
